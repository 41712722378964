@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Book.woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-LightItalic.woff2');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Medium.woff2');
  font-style: normal;
  font-weight: 500;
}
