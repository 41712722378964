.event-btn-container {
  position: relative;

  &:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  .free-seats {
    line-height: 1;
  }

  .btn {
    min-height: 2.375rem;
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: sm)) {
    .btn {
      padding: 0.25rem 0.375rem;
      font-size: 0.8em;
    }
    .free-seats {
      font-size: 0.8em;
    }
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: sm) + 1px) and (max-width: map-get($map: $grid-breakpoints, $key: md)) {
    .btn {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      font-size: $btn-font-size-sm;
      line-height: $btn-line-height-sm;
    }
    .free-seats {
      font-size: $btn-font-size-sm;
    }
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: lg)) {
    .btn {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      font-size: $btn-font-size-lg;
      line-height: $btn-line-height-lg;
    }
  }
}

$teens-aspect: 878/138;

.teens-logo {
  background-image: url(./teens-reg.png);
  width: 60px;
  height: 60px / $teens-aspect;
  background-size: 60px (60px / $teens-aspect);
  position: absolute;
  top: -16px;
  left: 50%;
  margin-left: -30px;
}

@media (max-width: map-get($map: $grid-breakpoints, $key: sm)) {
  .event-btn-container.with-teens {
    margin-top: 24px;
  }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: sm) + 1px) and (max-width: map-get($map: $grid-breakpoints, $key: md)) {
  .event-btn-container.with-teens {
    margin-top: 30px;
  }
  .teens-logo {
    width: 61px;
    height: (61px / $teens-aspect);
    background-size: 61px (61px / $teens-aspect);
    top: -15px;
    left: 50%;
    margin-left: -30px;
  }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: md)) {
  .teens-logo {
    width: 106px;
    height: (106px / $teens-aspect);
    background-size: 106px (106px / $teens-aspect);
    top: -28px;
    left: 50%;
    margin-left: -53px;
  }
}

.btn-teens {
  background-color: #f9d233 !important;
  color: #f73a02 !important;
}
