@import 'node_modules/bootstrap/scss/functions';

$primary: #eba921;
$secondary: #353536;
$info: #f9d233;

$font-family-sans-serif: 'Whitney', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$grid-breakpoints: (
  xs: 0,
  sm: 350px,
  md: 450px,
  lg: 600px,
  xl: 900px,
) !default;

// $enable-caret: true !default;
// $enable-rounded: true !default;
// $enable-shadows: true;
// $enable-gradients: true;
// $enable-transitions: true !default;
// $enable-prefers-reduced-motion-media-query: true !default;
// $enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
// $enable-grid-classes: true !default;
// $enable-pointer-cursor-for-buttons: true !default;
// $enable-print-styles: true !default;
// $enable-responsive-font-sizes: false !default;
// $enable-validation-icons: true !default;
// $enable-deprecation-messages: true !default;

@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
// @import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
// @import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
// @import 'node_modules/bootstrap/scss/transitions';
// @import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
// @import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
// @import 'node_modules/bootstrap/scss/nav';
// @import 'node_modules/bootstrap/scss/navbar';
// @import 'node_modules/bootstrap/scss/card';
// @import 'node_modules/bootstrap/scss/breadcrumb';
// @import 'node_modules/bootstrap/scss/pagination';
// @import 'node_modules/bootstrap/scss/badge';
// @import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
// @import 'node_modules/bootstrap/scss/progress';
// @import 'node_modules/bootstrap/scss/media';
// @import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
// @import 'node_modules/bootstrap/scss/toasts';
@import 'node_modules/bootstrap/scss/modal';
// @import 'node_modules/bootstrap/scss/tooltip';
// @import 'node_modules/bootstrap/scss/popover';
// @import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';
// @import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/utilities/flex';
@import 'node_modules/bootstrap/scss/utilities/float';
@import 'node_modules/bootstrap/scss/utilities/text';
@import 'node_modules/bootstrap/scss/utilities/spacing';
@import 'node_modules/bootstrap/scss/utilities/display';
// @import 'node_modules/bootstrap/scss/print';

@import 'fonts';

h1 {
  font-style: italic;
  font-weight: 300;
  font-size: 3rem;
}

.container-main {
  max-width: 820px;
}

@import './app/EventSelect.scss';

.easter-event-title {
  color: #bc855e;
  font-weight: 600;
  font-size: 1.3rem;
}
